import styled from "@emotion/styled";
import { forwardRef, RefObject } from "react";
import { TextRef } from "../appwrappers/Desktop";
import TableWithHorses from "../components/svg/table/TableWithHorses";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    position: absolute;
    right: 5%;
    width: 40%;
    top: 40%;
    svg {
      height: 100%;
    }
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      position: static;
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      height: 10rem;
      width: 100%;
      > svg {
        height: 100%;
      }
    }
  }
`;

const SeventhScreen = forwardRef<TextRef>((props, ref) => {
  const multiRef = (ref as RefObject<TextRef>)?.current
    ?.textmultiRef;
  const createMultiRefs = (
    multi: HTMLDivElement,
    index: number,
  ) => {
    if (multiRef?.current) {
      multiRef.current[index] = multi;
    }
  };
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <TableWithHorses />
      </div>
      <PageText
        lower="we at daalabs... "
        multi={
          <>
            <div ref={(e) => e && createMultiRefs(e, 0)}>
              …love to play and value chess
            </div>
            <div ref={(e) => e && createMultiRefs(e, 1)}>
              ... have learned over the decades how to
              achieve scalable impact in business, or how to
              deliver digital disruptions to industries at
              global scale
            </div>
            <div ref={(e) => e && createMultiRefs(e, 2)}>
              … had the privilege of being apprenticed at
              the world's most renowned financial
              institutions, management consultancies,
              industry-shaping players, and industry-feared
              disruptors.
            </div>
            <div ref={(e) => e && createMultiRefs(e, 3)}>
              {`… as a team, we love: to be together, to learn from each other and from our clients & partners, and we love to serve and fuel the digital disruptions we have the privilege to be part of`}
            </div>
          </>
        }
        ref={ref}
      />
      <SectionText text="who we are" />
    </ScreenWrapper>
  );
});

export default SeventhScreen;
