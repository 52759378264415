import styled from "@emotion/styled";
import { forwardRef } from "react";

const StyledSvg = styled.svg`
  position: absolute;
  fill: none;
  z-index: 0;
  top: 10%;
  left: 0;
  height: 100%;
  opacity: 0.7;
`;

const OpenLines = forwardRef<SVGElement>((props, ref) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1858.62 753.36"
      stroke="#b2f6ff"
    >
      <path d="M1858.55,752.86C1274.68,677.4,783.94,571.07,411,425.69l-5.32-2.08-5.3-2.08L395,419.41l-5.31-2.11-5.3-2.11q-5.31-2.13-10.58-4.27l-5.27-2.14-5.3-2.17L358,404.45l-5.22-2.16-5.25-2.18-5.26-2.2-5.15-2.17-5.25-2.22-5.29-2.26c-3.46-1.47-6.91-3-10.35-4.44L311,384.53l-4.82-2.11C190.9,332,88.69,277.32.39,218" />
      <path d="M1725.8,752.86C1202.79,674.53,759.42,569,415.29,429.38L410,427.23l-5.26-2.15-5.31-2.19-5.28-2.18-5.27-2.19q-5.26-2.19-10.49-4.4c-1.75-.74-3.5-1.47-5.24-2.22l-5.26-2.24q-2.61-1.1-5.2-2.22l-5.15-2.21L352.3,403l-5.2-2.26-5.18-2.27c-1.73-.76-3.45-1.51-5.17-2.28l-5.39-2.38q-5.19-2.29-10.33-4.61l-5.74-2.59-.14-.06q-4.51-2-9-4.09C191,329.83,88.76,272.8.39,211" />
      <path d="M1615.12,752.86C1142.69,672.44,738.93,567.81,419.63,433.1l-5.24-2.22-5.22-2.21-5.25-2.26-5.22-2.24-5.21-2.25q-5.24-2.26-10.43-4.55l-5.17-2.26-5.23-2.32-5.17-2.3c-1.7-.75-3.39-1.5-5.08-2.27s-3.46-1.54-5.19-2.33l-5.13-2.31c-1.72-.78-3.43-1.55-5.14-2.34s-3.42-1.55-5.12-2.34l-5.13-2.35q-5.06-2.32-10.07-4.67-5.69-2.64-11.34-5.31l-.14-.06-4.18-2C193.92,329.07,90.08,268.9.39,203.67" />
      <path d="M1521.22,752.86C1091.6,670.91,721.54,567.23,424,436.78l-5.19-2.28-5.19-2.29-5.2-2.31-5.18-2.32L398,425.23q-5.18-2.31-10.3-4.66l-5.17-2.35-5.2-2.39-5.1-2.35,0,0c-1.67-.76-3.33-1.54-5-2.31L362,408.73q-2.57-1.19-5.11-2.4c-1.69-.78-3.38-1.58-5.06-2.38l-5.19-2.46c-1.69-.79-3.37-1.6-5.05-2.4q-7.51-3.58-14.95-7.21l-5.6-2.72-4.78-2.34C197.16,328.33,91.54,264.86.39,196" />
      <path d="M1369.49,752.86C1008.86,669,693.6,567.4,432.61,444.12c-1.7-.8-3.4-1.6-5.1-2.42s-3.41-1.61-5.11-2.43l-5.09-2.42s0,0,0,0l-5.08-2.45-5.13-2.48L396.93,427l-5-2.47L386.8,422l-5-2.5-4.91-2.44-5.13-2.58q-2.49-1.25-5-2.5l-4.83-2.44-.08,0-4.75-2.42q-7.8-4-15.52-8l-4.88-2.54-5.34-2.78-4.76-2.51C203.57,326.46,94.46,256.1.39,179.68" />
      <path d="M1307.28,752.86C974.9,668.52,682.3,568,437,447.78l-5-2.48-5.07-2.5-5.1-2.54q-2.55-1.25-5.09-2.54t-5.08-2.54q-5-2.52-10.06-5.07-2.5-1.26-5-2.54L391.46,425l-5-2.57-.06,0-4.83-2.49-5.25-2.73q-2.54-1.31-5.06-2.64c-1.67-.86-3.33-1.74-5-2.61q-4.61-2.42-9.16-4.84l-10.47-5.58-4.8-2.59c-1.7-.91-3.39-1.82-5.08-2.75l-4.86-2.63C206.87,325.4,96,251.41.39,171" />
      <path d="M1252.06,752.86C944.77,668.24,672.43,568.78,441.34,451.45l-5-2.55-5-2.57-5-2.58-5.06-2.62-5-2.61-10-5.22q-2.51-1.31-5-2.63t-5-2.67l-5-2.66q-2.43-1.29-4.85-2.59l-5-2.7q-2.46-1.32-4.91-2.65c-3.38-1.82-6.75-3.66-10.11-5.49l-4.33-2.38-.08,0q-5-2.76-10-5.54L347,401.24l-5-2.81q-2.4-1.33-4.78-2.69C210.18,324.23,97.52,246.5.39,162" />
      <path d="M1202.78,752.86c-284.91-84.74-539-183.1-757.1-297.76l-5-2.63q-2.51-1.32-5-2.65l-5-2.66h0l-5-2.65-4.95-2.66q-5-2.68-9.93-5.38l-5-2.72-5-2.71c-1.67-.92-3.34-1.83-5-2.76l-4.82-2.66-4.68-2.6q-5-2.79-10-5.61c-1.71-.95-3.42-1.92-5.12-2.88L366.76,412q-5-2.81-9.88-5.64l-4.79-2.75-5-2.88-4.81-2.79C213.45,322.9,99.05,241.35.39,152.6" />
      <path d="M1158.54,752.86c-264.75-84.7-502.41-181.92-708.45-294.09l-5-2.72q-2.49-1.35-5-2.72t-5-2.76c-1.64-.9-3.28-1.8-4.91-2.72l-4.95-2.74q-4.94-2.75-9.83-5.5l-4.88-2.76-5-2.84-5-2.82-4.47-2.56q-4.89-2.81-9.75-5.62-2.56-1.47-5.11-3l-5-2.89-4.58-2.69q-4.89-2.87-9.74-5.74l-4.77-2.84q-2.48-1.45-4.92-2.93l-4.75-2.85C216.82,321.5,100.65,236,.39,142.88" />
      <path d="M1118.65,752.86c-246.57-84.54-469.27-180.64-664.2-290.47l-4.94-2.79-4.89-2.78-4.93-2.8q-2.44-1.39-4.88-2.8l-4.93-2.83q-4.9-2.82-9.77-5.66-2.48-1.43-4.93-2.88L410.29,437l-.08-.05-5.15-3-8.92-5.29-5.07-3-4.77-2.86-4.74-2.86-4.7-2.84q-4.86-2.94-9.69-5.9l-4.76-2.93q-2.45-1.49-4.86-3l-4.75-2.94C220.22,320,102.28,230.43.39,132.82" />
      <path d="M1082.59,752.86C852.51,668.59,643.48,573.64,458.83,466l-4.89-2.86-4.87-2.85-4.86-2.87,0,0c-1.62-.94-3.23-1.9-4.84-2.86s-3.27-1.93-4.89-2.9q-4.77-2.84-9.51-5.69L420.08,443l-4.8-2.9-.13-.08q-5.06-3.06-10.09-6.14l-4.45-2.73-4.72-2.92-4.74-2.93-4.7-2.92-.06,0-4.62-2.89q-4.8-3-9.57-6l0,0-4.68-3c-1.6-1-3.2-2-4.79-3.05l-4.68-3C223.64,318.37,103.93,224.65.39,122.41" />
      <path d="M1049.87,752.86c-215-83.9-411.52-177.7-586.6-283.16l-4.85-2.94q-2.4-1.44-4.78-2.9l-4.86-2.95L444,458,439.16,455q-4.79-2.94-9.54-5.9l-4.68-2.92q-4.85-3-9.66-6.05l-.13-.08c-1.62-1-3.24-2-4.86-3.06l-.08-.05c-1.54-1-3.09-1.95-4.63-2.94l-4.69-3c-1.58-1-3.16-2-4.73-3s-3.14-2-4.7-3l-4.66-3-9.5-6.16-4.64-3q-2.39-1.56-4.76-3.13l-4.64-3.05c-136.15-90-257.62-188-362.87-294.93" />
      <path d="M1020.07,752.86C818.75,669.39,633.78,576.73,467.62,473.27q-2.42-1.49-4.81-3t-4.73-3c-1.62-1-3.23-2-4.84-3s-3.23-2-4.84-3.05-3.21-2-4.81-3.06q-4.8-3-9.58-6.12-4.54-2.91-9.07-5.84L420.08,443l-4.9-3.19-4.59-3-4.74-3.11-4.65-3.07q-2.35-1.55-4.68-3.1t-4.62-3.07l-9.4-6.28-4.61-3.11-4.73-3.21-4.6-3.12C230.65,314.82,107.39,212.45.39,100.58" />
      <path d="M992.87,752.86c-188.75-83-363.11-174.5-520.94-276l-4.73-3.05q-2.34-1.5-4.66-3l-4.72-3.06-4.76-3.11-4.95-3.24Q441,456.7,434,452l-4.39-2.92L424.93,446l-4.82-3.24-4.64-3.12-4.64-3.15q-2.32-1.57-4.64-3.16l-4.68-3.19L396.93,427q-4.65-3.2-9.26-6.4l-4.61-3.2-4.66-3.25-4.57-3.2C234.22,312.89,109.17,206,.39,89.15" />
      <path d="M945.22,752.86c-166.38-81.75-321.68-170.92-464.29-268.7l-4.6-3.16-4.58-3.16L467,474.53l-4.49-3.12q-7.22-5-14.37-10.06l-4.52-3.2L439.16,455l-4.72-3.35-4.56-3.27-4.58-3.28-4.54-3.27-4.59-3.32-4.6-3.34-4.49-3.27q-4.57-3.33-9.11-6.68l-4.48-3.31-4.6-3.4-4.48-3.33C241.52,308.74,112.91,192.58.39,65.33" />
      <path d="M924.34,752.86C767.79,671.76,621,583.72,485.22,487.66l-4.56-3.23-4.7-3.36c-1.69-1.19-3.37-2.4-5-3.61q-4.23-3-8.43-6-4.73-3.41-9.42-6.82l-4.66-3.38L444,458l-4.62-3.39q-2.26-1.67-4.52-3.34t-4.56-3.37c-1.52-1.11-3-2.24-4.54-3.35l-4.51-3.37-4.55-3.41-4.44-3.33q-4.51-3.39-9-6.81l-4.5-3.41-4.54-3.46-4.45-3.41C245.26,306.52,114.85,185.56.39,52.94" />
      <path d="M905.14,752.86C757.77,672.47,618.89,585.61,489.69,491.27L485.22,488c-1.53-1.12-3.05-2.23-4.57-3.36q-4.87-3.57-9.74-7.18L467,474.53c-3.06-2.27-6.1-4.55-9.15-6.83l-4.58-3.44-4.46-3.35-4.57-3.46,0,0-4.48-3.4-4.53-3.45-4.46-3.41h0q-2.22-1.69-4.43-3.4t-4.52-3.49l-4.43-3.41s0,0,0,0c-3-2.31-6-4.62-8.91-6.94q-2.24-1.74-4.46-3.49l-4.48-3.52c-1.47-1.15-2.95-2.31-4.42-3.48C249.05,304.2,116.86,178.35.39,40.23" />
      <path d="M887.49,752.86Q679.17,633.37,494,494.78L490,491.71a1.63,1.63,0,0,1-.22-.17q-4.55-3.44-9.08-6.9c-1.56-1.18-3.13-2.37-4.69-3.57-1.4-1.07-2.81-2.14-4.21-3.23-3.08-2.34-6.15-4.71-9.21-7.08q-2.24-1.71-4.46-3.46c-1.48-1.13-3-2.27-4.44-3.44s-3.05-2.36-4.57-3.54-3-2.32-4.45-3.5-3-2.31-4.45-3.49-3-2.33-4.47-3.51-2.94-2.32-4.41-3.49-3-2.34-4.45-3.53-3-2.34-4.44-3.53q-4.41-3.51-8.82-7.06l-4.41-3.54c-1.48-1.19-2.95-2.38-4.42-3.59s-2.92-2.35-4.38-3.55C252.93,301.81,118.93,171,.39,27.2" />
      <path d="M871.26,752.86q-196.59-118.41-373-254.7-4.16-3.21-8.28-6.45a1.63,1.63,0,0,1-.22-.17c-1.5-1.17-3-2.35-4.51-3.54s-3-2.37-4.56-3.57-2.89-2.27-4.33-3.43q-4.57-3.58-9.13-7.22c-1.46-1.16-2.93-2.33-4.39-3.51s-2.93-2.33-4.39-3.51-3-2.39-4.48-3.59-3-2.37-4.43-3.57-2.92-2.35-4.38-3.55-2.95-2.37-4.43-3.58-2.92-2.37-4.38-3.57-2.94-2.38-4.41-3.6-2.94-2.39-4.4-3.6q-4.38-3.58-8.74-7.2l-4.38-3.62c-1.46-1.21-2.92-2.42-4.38-3.65s-2.9-2.4-4.34-3.62C256.83,299.29,121,163.35.39,13.9" />
      <path d="M856.32,752.86q-188-118.74-358.09-254.7c-1.4-1.12-2.79-2.24-4.19-3.38s-2.9-2.33-4.35-3.51l-4.47-3.61c-1.43-1.16-2.86-2.32-4.29-3.5-3-2.43-6-4.88-9-7.33-1.44-1.18-2.87-2.36-4.31-3.56l-4.35-3.57L458.83,466q-2.19-1.8-4.38-3.64c-1.45-1.19-2.91-2.4-4.36-3.62l-4.41-3.67-4.34-3.65c-1.46-1.21-2.92-2.44-4.38-3.67l-4.35-3.66q-4.33-3.66-8.65-7.34-2.18-1.83-4.33-3.68c-1.45-1.24-2.9-2.47-4.34-3.72s-2.87-2.45-4.3-3.69C260.81,296.71,123.23,155.59.39.31" />
    </StyledSvg>
  );
});

export default OpenLines;
