import styled from "@emotion/styled";
import HorseSmall from "../components/svg/horse/HorseSmall";
import backgroundImage from "../backgrounds/landing1.png";
import backgroundImageMid from "../backgrounds/landing1-mid.png";
import { forwardRef } from "react";
import { useWindowSize } from "../hooks/useWindowSize";

const StyledWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .horses {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    bottom: 10%;
    left: 5%;
    width: 30rem;
    height: 10rem;
  }

  .tagline {
    position: absolute;
    bottom: 40%;
    left: 30%;
    font-size: 2.25rem;
  }

  .scroll-indicator {
    position: absolute;
    display: flex;
    right: 5%;
    bottom: 22%;
    width: 40%;

    > .line {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
      flex-grow: 1;
    }

    > .text {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-height: 900px) and (max-width: 1500px) {
    .tagline {
      font-size: 1.25rem;
    }
    .scroll-indicator {
      right: 2%;
      > .text {
        font-size: 0.6rem;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    background-image: url(${backgroundImageMid});
    background-size: cover;
    background-repeat: no-repeat;

    .horses {
      height: 12rem;
      width: 12rem;
    }

    .scroll-indicator {
      display: none;
    }

    .tagline {
      font-size: 1.1rem;
      bottom: 28%;
      left: 5%;
    }
  }

  @media screen and (max-width: 700px) {
    background-image: url(${backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const FirstScreen = forwardRef<HTMLHeadingElement>(
  (props, ref) => {
    const { height, width } = useWindowSize();
    return (
      <StyledWrapper>
        <div className="horses">
          <HorseSmall
            height={
              height && height < 900 ? "4.5rem" : "7rem"
            }
          />
          <HorseSmall
            height={
              height && height < 900 ? "7.2rem" : "10rem"
            }
            marginBottom="-0.5rem"
          />
          <HorseSmall
            height={
              height && height < 900 ? "4.5rem" : "7rem"
            }
          />
        </div>
        <h1 className="tagline" ref={ref}>
          we make ai work in production
        </h1>
        <div className="scroll-indicator">
          <div className="line"></div>
          <div className="text">
            scroll down to get to know us
          </div>
        </div>
      </StyledWrapper>
    );
  },
);

export default FirstScreen;
