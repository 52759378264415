import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Amorph from "../components/svg/amorph/Amorph";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      svg {
        margin: 2rem 0;
        stroke: #b2f6ff;
        stroke-width: 0.5px;
        fill: none;
        height: 15rem;
        > path {
          visibility: initial;
        }
      }
    }
  }
`;

const SecondScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 511 491"
        >
          <Amorph />
        </svg>
      </div>
      <PageText
        upper="we do what we know best"
        lower="we make ai work in production"
        paragraph="our solutions are scalable, integrate seamlessly, and drive impact at scale in production-level environments, going beyond pilots and business cases – we produce millions of dollars in impact at the world's leading companies"
        ref={ref}
      />
      <SectionText text="our strengths" />
    </ScreenWrapper>
  );
});

export default SecondScreen;
