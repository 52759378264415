import styled from "@emotion/styled";
import gsap from "gsap";
import SplitText from "gsap/SplitText";
import { forwardRef, ReactNode, RefObject } from "react";
import { TextRef } from "../../appwrappers/Desktop";

gsap.registerPlugin(SplitText);

interface Props {
  upper?: string;
  lower: string;
  paragraph?: string;
  other?: ReactNode;
  multi?: ReactNode;
}

const StyledWrapper = styled.div<{
  upper?: string;
}>`
  will-change: transform, opacity, contents;
  position: absolute;
  z-index: 10;
  bottom: 8%;
  left: 5%;
  text-align: left;

  h1 {
    font-size: 2.25rem;
    margin-left: ${({ upper }) => upper && "4rem"};
    max-width: 45rem;
  }

  p,
  .multi-wrapper {
    white-space: pre-line;
    margin-top: 5rem;
    max-width: 36rem;
  }

  .split-parent {
    overflow: hidden;
  }
  .split-child {
    display: inline-block;
  }

  .multi-wrapper {
    > div {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-height: 910px) {
    bottom: 3%;
    left: 5%;
    text-align: left;

    h1 {
      font-size: 2rem;
      margin-left: ${({ upper }) => upper && "4rem"};
      max-width: 45rem;
    }

    p,
    .multi-wrapper {
      margin-top: 3rem;
    }
  }

  @media screen and (max-height: 740px) {
    p,
    .multi-wrapper {
      margin-top: 2rem;
    }
    .multi-wrapper {
      > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  @media screen and (max-height: 900px) and (max-width: 1500px) {
    h3,
    p,
    .multi-wrapper {
      font-size: 0.9rem;
    }

    h1 {
      margin-left: 1rem;
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 1100px) {
    position: static;
    min-height: 18rem;
    margin-bottom: 2rem;

    h3,
    p,
    .multi-wrapper {
      font-size: 0.7rem;
      max-width: fit-content;
    }

    h1 {
      margin-left: 1rem;
      font-size: 1.25rem;
    }

    p,
    .multi-wrapper {
      max-width: 25rem;
      margin-top: 1rem;
    }
  }
`;

const PageText = forwardRef<TextRef, Props>(
  ({ upper, lower, paragraph, other, multi }, ref) => {
    const pRef = (ref as RefObject<TextRef>)?.current
      ?.textpRef;
    const wrapperRef = (ref as RefObject<TextRef>)?.current
      ?.textwrapperRef;
    return (
      <StyledWrapper upper={upper} ref={wrapperRef}>
        {upper && <h3>{upper}</h3>}
        <h1>{lower}</h1>
        {!multi && <p ref={pRef}>{paragraph}</p>}
        {multi && (
          <div className="multi-wrapper">{multi}</div>
        )}
        {other && other}
      </StyledWrapper>
    );
  },
);

export default PageText;
