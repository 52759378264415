import styled from "@emotion/styled";
import { forwardRef } from "react";

const StyledSvg = styled.svg`
  position: absolute;
  fill: none;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.3;
`;

const AiLines = forwardRef<SVGElement>((props, ref) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1391.56 1080.71"
      stroke="#b2f6ff"
    >
      <path d="M1391.34,1080.26q-251.19-121-465.25-247.79l-8-4.78-8-4.79L902,818.06l-8-4.84-8-4.82q-8-4.86-16-9.72l-7.92-4.86-8-4.92L846.19,784q-3.93-2.43-7.84-4.88t-8-5l-7.78-4.87q-3.95-2.47-7.86-5l-7.89-5q-3.9-2.46-7.78-4.95-7.83-5-15.61-10-4-2.58-8-5.18l-7.18-4.66C415.15,505,154.92,261,.43.26" />
      <path d="M1367.65,1080.26Q1133.44,962.68,932.06,839.58l-7.95-4.87-8-4.9-7.94-4.92-8-4.94-7.9-4.93q-7.92-5-15.77-9.92l-7.83-5-7.89-5c-2.64-1.67-5.27-3.36-7.9-5l-7.7-4.94q-4-2.53-7.91-5.1t-7.77-5l-7.77-5L814,764.89l-7.8-5.12q-7.72-5.07-15.39-10.16L782.48,744q-7.14-4.77-14.22-9.54C427.39,505.08,173.39,261,18.18.26" />
      <path d="M1345.65,1080.26Q1127.4,966.1,938.09,846.75l-7.88-5-7.87-5-7.84-5q-3.94-2.52-7.88-5t-7.79-5q-7.82-5-15.58-10.09l-7.76-5.06-7.81-5.12-7.8-5.13-7.55-5-7.85-5.22L836.82,781l-7.64-5.12q-3.88-2.61-7.76-5.23t-7.6-5.13q-7.5-5.09-15-10.19-8.22-5.61-16.38-11.25l-7-4.88C442.89,508.41,192.61,262.83,35.89.26" />
      <path d="M1325.32,1080.26Q1122,969.5,944.1,853.85l-7.81-5.1-7.79-5.1-7.76-5.12q-3.9-2.56-7.8-5.15t-7.71-5.12q-7.76-5.14-15.45-10.32l-7.66-5.14q-3.89-2.62-7.76-5.26t-7.73-5.24l-7.49-5.09c-2.6-1.78-5.19-3.55-7.78-5.34s-5-3.43-7.51-5.16l-7.56-5.21-7.75-5.39-7.4-5.15q-11.21-7.82-22.28-15.67-4-2.84-8-5.68-3.69-2.62-7.37-5.27C458.56,512.1,211.67,264.83,53.52.26" />
      <path d="M1289,1080.26Q1112.74,976.28,956.11,868q-3.81-2.62-7.6-5.27l-7.61-5.29-7.63-5.35q-3.81-2.65-7.6-5.34c-2.51-1.75-5-3.52-7.51-5.28q-7.59-5.37-15.14-10.75-3.75-2.67-7.49-5.35L888,820l-7.58-5.46-7.25-5.24q-3.78-2.74-7.55-5.5t-7.44-5.43q-3.66-2.65-7.29-5.33L843,787.17q-11-8.08-21.81-16.21l-7.32-5.48q-3.8-2.85-7.58-5.71-3.59-2.7-7.15-5.42C489,519.19,248.91,268.63,88.57.26" />
      <path d="M1272.87,1080.26Q1109,979.69,962.17,875.12q-3.76-2.67-7.51-5.37t-7.52-5.4q-3.78-2.7-7.53-5.41l-7.52-5.45-7.47-5.43q-7.49-5.44-14.93-10.92-3.74-2.73-7.44-5.48t-7.46-5.51q-3.76-2.77-7.51-5.57l-7.15-5.32-7.59-5.7-7.26-5.46q-3.49-2.62-7-5.26Q850.59,793,843,787.17q-7.23-5.52-14.41-11.06l-7.12-5.5q-3.7-2.85-7.38-5.72t-7.17-5.59C503.84,522.68,267.15,270.5,106,.26" />
      <path d="M1258,1080.26q-152.37-97.18-289.78-198.08l-7.42-5.47-7.44-5.5-7.46-5.54-7.43-5.54-7.39-5.53q-7.43-5.57-14.78-11.15-3.66-2.75-7.31-5.54t-7.35-5.59l-7.52-5.75q-3.54-2.7-7.05-5.41l-7.48-5.77c-2.39-1.85-4.78-3.69-7.16-5.55q-7.08-5.49-14.12-11L850.88,793q-7.32-5.74-14.59-11.52l-7.11-5.66q-3.69-2.93-7.35-5.86t-7.07-5.68C518.58,526.21,285.18,272.38,123.34.26" />
      <path d="M1244.27,1080.26q-141.46-93.76-270-191l-7.39-5.62q-3.69-2.79-7.36-5.61l-7.34-5.6s0,0,0,0l-7.34-5.64-7.29-5.63q-7.34-5.65-14.61-11.34c-2.4-1.87-4.81-3.74-7.2-5.62s-4.89-3.82-7.32-5.74-5-3.9-7.44-5.86l-6.68-5.27-7.39-5.87q-7.27-5.81-14.51-11.61-3.58-2.87-7.14-5.74t-7.11-5.75q-7.18-5.8-14.32-11.64-3.52-2.87-7-5.75L829.6,775q-3.5-2.88-7-5.77C533.1,529.69,303,274.23,140.58.26" />
      <path d="M1231.62,1080.26Q1100.4,989.89,980.4,896.33l-7.29-5.7q-3.63-2.83-7.26-5.7l-7.26-5.72-7.29-5.77-7.17-5.7q-7.26-5.78-14.48-11.57-3.58-2.86-7.15-5.75l-7.17-5.79q-3.8-3.07-7.58-6.16-3.27-2.64-6.53-5.32-7.17-5.83-14.29-11.7l-7.35-6.06-7-5.83c-2.32-1.92-4.62-3.84-6.93-5.78q-7.14-5.94-14.25-11.91l-6.88-5.8q-3.57-3-7.11-6l-7-5.92C547.41,533.11,320.56,276,157.77.26" />
      <path d="M1220,1080.26q-121.65-87-233.62-177-3.6-2.88-7.19-5.79l-7.16-5.79-7.16-5.81L957.7,880l-7.1-5.82q-7.2-5.9-14.36-11.83-3.54-2.91-7.08-5.86l-7-5.86-8.21-6.86q-6.36-5.32-12.68-10.66l-6.9-5.83-7.26-6.16-6.93-5.9-7-6q-7-6-14-12.07l-6.81-5.92-7.05-6.14-6.93-6C561.65,536.59,338,277.88,174.86.26" />
      <path d="M1209.38,1080.26q-112.56-83.58-216.79-169.83l-7.09-5.89q-3.54-2.92-7.07-5.89t-7-5.88l-7.07-5.94c-2.34-2-4.68-3.93-7-5.91q-7-5.92-14-11.89-3.43-2.91-6.86-5.85c-2.18-1.85-4.36-3.72-6.53-5.58q-8-6.89-16-13.79l-6.15-5.34L901,832.59l-6.89-6-6.83-6-6.87-6.06q-6.92-6.1-13.78-12.22c-2.25-2-4.51-4-6.75-6s-4.61-4.12-6.9-6.19-4.53-4.07-6.79-6.11C575.65,540,355.14,279.69,191.86.26" />
      <path d="M1199.68,1080.26q-104.06-80.19-201-162.84l-7-6-7-6-6.94-6-7-6.07q-3.5-3-7-6.05-6.93-6-13.82-12.07L942.8,869q-6.46-5.68-12.9-11.4-3.9-3.45-7.79-6.93-3.39-3-6.78-6c-2.3-2.06-4.6-4.11-6.89-6.18s-4.54-4.08-6.81-6.13l-6.84-6.17L888,820q-6.84-6.21-13.63-12.44-3.35-3.06-6.68-6.13t-6.8-6.28l-6.74-6.23C589.57,543.44,372.16,281.49,208.76.26" />
      <path d="M1190.86,1080.26q-96.06-76.79-186.06-155.84-3.48-3-6.94-6.11t-7-6.15L984,906.07q-3.49-3.09-7-6.22t-6.88-6.15q-6.93-6.19-13.82-12.43T942.8,869l-6.37-5.82q-3.64-3.31-7.27-6.65l-6.66-6.11-6.74-6.23c-2.27-2.08-4.52-4.18-6.78-6.28s-4.49-4.16-6.73-6.25l-6.72-6.27q-6.72-6.28-13.41-12.59l-6.63-6.27q-3.37-3.18-6.72-6.38t-6.65-6.33C603.38,546.88,389,283.3,225.61.26" />
      <path d="M1182.82,1080.26q-88.5-73.4-171.87-148.82-3.44-3.11-6.86-6.22c-2.29-2.07-4.58-4.16-6.86-6.24s-4.54-4.14-6.8-6.22l-.06-.06c-2.26-2.05-4.51-4.12-6.75-6.18s-4.58-4.21-6.86-6.33q-10.25-9.42-20.4-18.92-3.24-3-6.46-6t-6.61-6.2q-3.52-3.3-7-6.64l-6.59-6.22L923,849.81q-3.35-3.16-6.67-6.36t-6.6-6.31l-6.67-6.4q-6.64-6.39-13.24-12.8l-6.53-6.35q-3.33-3.23-6.65-6.49c-2.19-2.13-4.38-4.28-6.56-6.42C617,550.25,405.7,285.07,242.37.26" />
      <path d="M1169.07,1080.26q-74.71-66.59-145.83-134.86-3.29-3.15-6.56-6.31T1010,932.6q-3.35-3.23-6.68-6.48l-6.57-6.38q-10-9.75-20-19.55l-6.58-6.49-6.46-6.4-6.41-6.38q-3.36-3.33-6.71-6.7c-2.17-2.15-4.32-4.32-6.47-6.48s-4.38-4.39-6.56-6.59l-6.5-6.55-6.45-6.54-6.46-6.57q-6.48-6.6-12.93-13.23-3.21-3.28-6.4-6.58L892.37,815q-3.18-3.32-6.38-6.62C643.94,557,438.59,288.62,275.62.26" />
      <path d="M1163.24,1080.26q-68.4-63.18-133.79-127.84l-6.4-6.34-6.69-6.66-6.72-6.7-.08-.09q-6.45-6.43-12.85-12.89-6.57-6.6-13.09-13.22l-6.56-6.67q-3.19-3.26-6.37-6.5t-6.37-6.52Q961,883.44,957.7,880l-6.4-6.6-6.44-6.66-6.4-6.65-6.37-6.64-6.42-6.72q-6.39-6.69-12.73-13.39-3.16-3.35-6.32-6.68L900.27,820,894,813.22C657.21,560.35,454.79,290.36,292.1.26" />
      <path d="M1158.08,1080.26q-62.46-59.77-122.45-120.88-3.15-3.2-6.27-6.4t-6.51-6.67q-6.61-6.78-13.21-13.59l-.08-.09q-3.15-3.25-6.28-6.51-6.45-6.68-12.85-13.36l-.06-.06q-3.18-3.32-6.33-6.63l-6.34-6.65q-3.16-3.31-6.31-6.65t-6.48-6.86q-3.18-3.35-6.32-6.7l-6.37-6.77s0,0,0,0l-6.31-6.75q-3.15-3.36-6.28-6.73l-6.34-6.83q-6.28-6.78-12.53-13.58-3.13-3.39-6.24-6.78t-6.28-6.86L902,818.06C670.41,563.71,470.87,292.11,308.52.26" />
      <path d="M1153.53,1080.26q-56.92-56.43-111.81-114l-6-6.28,0,0q-6.43-6.78-12.85-13.59-3.26-3.43-6.49-6.89t-6.4-6.82q-6.39-6.81-12.73-13.62l-6.32-6.82-6.27-6.77-6.21-6.74q-3.2-3.46-6.36-6.93t-6.22-6.79q-3.17-3.45-6.29-6.89t-6.21-6.83q-3.12-3.42-6.21-6.86c-2.09-2.29-4.16-4.59-6.24-6.89q-6.21-6.9-12.4-13.81l-6.16-6.89-6.18-7L910,822.9C683.53,567.06,486.83,293.86,324.87.26" />
      <path d="M1149.59,1080.26q-51.83-53.22-102-107.47-5.94-6.42-11.86-12.85l0,0q-3.18-3.45-6.34-6.92t-6.31-6.9q-3.19-3.5-6.37-7-6.31-6.93-12.59-13.87l-6.23-6.91-6.2-6.9-6.16-6.87-6.27-7q-3.08-3.43-6.12-6.88l-6.19-7q-3.07-3.46-6.13-6.94l-6.13-7-6.15-7q-6.12-7-12.22-14l-6.08-7-6.1-7-6-7C696.51,570.37,502.62,295.58,341.1.26" />
      <path d="M1146.19,1080.26q-50.06-53.22-98.6-107.47-3-3.28-5.87-6.57t-6.09-6.84q-3.09-3.46-6.18-7c-2.07-2.33-4.15-4.68-6.21-7q-6.16-7-12.29-14-3.09-3.51-6.15-7l-6.12-7-6.09-7q-3.09-3.55-6.17-7.13t-6-7c-2-2.35-4.07-4.71-6.09-7.06s-4.07-4.73-6.1-7.09-4-4.71-6-7.06l-6.06-7.1q-6-7.08-12-14.17-3-3.54-6-7.1l-6-7.17-6-7.11C709.41,573.67,518.3,297.3,357.28.26" />
    </StyledSvg>
  );
});

export default AiLines;
