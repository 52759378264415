import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Cube from "../components/svg/cube/Cube";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      svg {
        height: 15rem;
      }
    }
  }
`;

const FourthScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <Cube />
      </div>
      <PageText
        upper="building never-seen-before solutions"
        lower="unique ai business-solutions"
        paragraph="we are both business and digital geeks, so we are ideal partners in identifying new sources of value and finding pragmatic ways to capture value - end-to-end and fast"
        ref={ref}
      />
      <SectionText text="our strengths" />
    </ScreenWrapper>
  );
});

export default FourthScreen;
