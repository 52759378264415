import { forwardRef } from "react";

const SpiralOriginal = forwardRef<SVGPathElement>(
  (props, ref) => {
    return (
      // <svg
      //   width="245"
      //   height="238"
      //   viewBox="0 0 245 238"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      <path ref={ref} d="M1069.86,926.74l-.06,0" />
      /* <defs>
        <linearGradient
          id="paint0_linear_64_594"
          x1="-6.64711"
          y1="137.795"
          x2="260.242"
          y2="151.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B3F6FF" />
          <stop offset="1" stop-color="#B3F6FF" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg> */
    );
  },
);

export default SpiralOriginal;
