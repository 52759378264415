import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Ai from "../components/svg/ai/Ai";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;

    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      svg {
        height: 15rem;
      }
    }
  }
`;

const ThirdScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <Ai />
      </div>
      <PageText
        lower="actionable insights from artificial intelligence"
        paragraph="we rely on ai solely for insights that are actionable and relevant - this brutal focus enables our solutions to react dynamically, capturing unparalleled impact"
        ref={ref}
      />
      <SectionText text="our strengths" />
    </ScreenWrapper>
  );
});

export default ThirdScreen;
