import styled from "@emotion/styled";

const StyledWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 0.5rem;
  font-size: 0.6rem;
  color: #b2f6ff;

  span {
    margin-right: 0.2rem;
  }

  a {
    text-decoration: underline;
    color: #b2f6ff;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.6rem;
  }
`;

const ArtificialText = () => {
  return (
    <StyledWrapper>
      <span>{`a website by `}</span>
      <a
        href="https://www.artificial-id.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        artificial identity
      </a>
    </StyledWrapper>
  );
};

export default ArtificialText;
