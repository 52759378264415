import styled from "@emotion/styled";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import {
  forwardRef,
  MutableRefObject,
  useLayoutEffect,
  useRef,
} from "react";
import { MenuRef } from "../App";
import EighthScreen from "../screens/EighthScreen";
import FifthScreen from "../screens/FifthScreen";
import FirstScreen from "../screens/FirstScreen";
import FourthScreen from "../screens/FourthScreen";
import NinethScreen from "../screens/NinethScreen";
import SecondScreen from "../screens/SecondScreen";
import SeventhScreen from "../screens/SeventhScreen";
import SixthScreen from "../screens/SixthScreen";
import ThirdScreen from "../screens/ThirdScreen";
import { TextRef } from "./Desktop";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const StyledAppWrapper = styled.div`
  > .panel {
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: white;
    position: relative;
    z-index: 0;
    padding: 5rem 1rem 1rem 1rem;
  }
`;

const Mobile = forwardRef<MenuRef>((props, ref) => {
  const menuRefs = (ref as MutableRefObject<MenuRef>)
    .current;

  const panels = menuRefs?.panels;
  const panelsContainer = menuRefs?.container;

  const text2wrapperRef = useRef<HTMLDivElement>(null);
  const text2pRef = useRef<HTMLParagraphElement>(null);

  const text3wrapperRef = useRef<HTMLDivElement>(null);
  const text3pRef = useRef<HTMLDivElement>(null);
  const text4wrapperRef = useRef<HTMLDivElement>(null);
  const text4pRef = useRef<HTMLDivElement>(null);
  const text5wrapperRef = useRef<HTMLDivElement>(null);
  const text5pRef = useRef<HTMLDivElement>(null);
  const text6wrapperRef = useRef<HTMLDivElement>(null);
  const text6pRef = useRef<HTMLDivElement>(null);
  const text7wrapperRef = useRef<HTMLDivElement>(null);
  const text7pRef = useRef<HTMLDivElement>(null);
  const text7multiRef = useRef<HTMLDivElement[]>([]);
  const text8wrapperRef = useRef<HTMLDivElement>(null);
  const text8pRef = useRef<HTMLDivElement>(null);
  const text8multiRef = useRef<HTMLDivElement[]>([]);

  const text2ref = useRef<TextRef | null>(null);
  const text3ref = useRef<TextRef | null>(null);
  const text4ref = useRef<TextRef | null>(null);
  const text5ref = useRef<TextRef | null>(null);
  const text6ref = useRef<TextRef | null>(null);
  const text7ref = useRef<TextRef | null>(null);
  const text8ref = useRef<TextRef | null>(null);

  text2ref.current = {
    textwrapperRef: text2wrapperRef,
    textpRef: text2pRef,
  };
  text3ref.current = {
    textwrapperRef: text3wrapperRef,
    textpRef: text3pRef,
  };
  text4ref.current = {
    textwrapperRef: text4wrapperRef,
    textpRef: text4pRef,
  };
  text5ref.current = {
    textwrapperRef: text5wrapperRef,
    textpRef: text5pRef,
  };
  text6ref.current = {
    textwrapperRef: text6wrapperRef,
    textpRef: text6pRef,
  };
  text7ref.current = {
    textwrapperRef: text7wrapperRef,
    textmultiRef: text7multiRef,
    textpRef: text7pRef,
  };
  text8ref.current = {
    textwrapperRef: text8wrapperRef,
    textmultiRef: text8multiRef,
    textpRef: text8pRef,
  };

  const createPanelsRefs = (
    panel: HTMLElement,
    index: number,
  ) => {
    panels.current[index] = panel;
  };

  useLayoutEffect(() => {
    document.fonts.ready.then(() => {
      if (text2ref.current) {
        const text2wrapper =
          text2ref.current?.textwrapperRef;
        const text2P = text2ref.current?.textpRef;
        const childSplit = new SplitText(text2P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text2P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text2wrapper.current, {
          duration: 1,
          x: -200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[1],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[1],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text3ref.current) {
        const text3wrapper =
          text3ref.current?.textwrapperRef;
        const text3P = text3ref.current?.textpRef;
        const childSplit = new SplitText(text3P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text3P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text3wrapper.current, {
          duration: 1,
          y: -200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[2],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[2],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text4ref.current) {
        const text4wrapper =
          text4ref.current?.textwrapperRef;
        const text4P = text4ref.current?.textpRef;
        const childSplit = new SplitText(text4P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text4P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text4wrapper.current, {
          duration: 1,
          x: 200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[3],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[3],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text5ref.current) {
        const text5wrapper =
          text5ref.current?.textwrapperRef;
        const text5P = text5ref.current?.textpRef;
        const childSplit = new SplitText(text5P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text5P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text5wrapper.current, {
          duration: 1,
          y: 200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[4],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[4],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });
      }

      if (text6ref.current) {
        const text6wrapper =
          text6ref.current?.textwrapperRef;
        const text6P = text6ref.current?.textpRef;
        const childSplit = new SplitText(text6P?.current, {
          type: "lines",
          linesClass: "split-child",
        });
        const parentSplit = new SplitText(text6P?.current, {
          linesClass: "split-parent",
        });

        gsap.from(text6wrapper.current, {
          duration: 1,
          x: -200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[5],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        gsap.from(childSplit.lines, {
          duration: 1.5,
          delay: 1,
          yPercent: 200,
          ease: "power4",
          stagger: 0.2,
          scrollTrigger: {
            trigger: panels.current[5],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });
      }

      if (
        text7ref.current &&
        text7ref.current?.textmultiRef
      ) {
        const text7wrapper =
          text7ref.current?.textwrapperRef;
        const text7multi = text7ref.current?.textmultiRef;

        gsap.from(text7wrapper.current, {
          duration: 1,
          y: -200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[6],
            start: "top 20%",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        if (text7multi.current) {
          text7multi.current.map((child, index) =>
            gsap.from(child, {
              duration: 0.5,
              delay: index * 0.5 + 1,
              y: 200,
              autoAlpha: 0,
              ease: "power4",
              scrollTrigger: {
                trigger: panels.current[6],
                start: "top 20%",
                end: "bottom bottom",
                toggleActions: "play none none reset",
              },
            }),
          );
        }
      }

      if (
        text8ref.current &&
        text8ref.current?.textmultiRef
      ) {
        const text8wrapper =
          text8ref.current?.textwrapperRef;
        const text8multi = text8ref.current?.textmultiRef;

        gsap.from(text8wrapper.current, {
          duration: 1,
          x: 200,
          autoAlpha: 0,
          ease: "power4",
          scrollTrigger: {
            trigger: panels.current[7],
            start: "top top",
            end: "bottom bottom",
            toggleActions: "play none none reset",
          },
        });

        if (text8multi.current) {
          text8multi.current.map((child, index) =>
            gsap.from(child, {
              duration: 0.5,
              delay: index * 0.5 + 1,
              y: 200,
              autoAlpha: 0,
              ease: "power4",
              scrollTrigger: {
                trigger: panels.current[7],
                start: "top 20%",
                end: "bottom bottom",
                toggleActions: "play none none reset",
              },
            }),
          );
        }
      }
    });

    return () => {
      const sc = ScrollTrigger.getAll();
      sc.map((trigger) => trigger.kill());
      panels.current = [];
    };
  }, [panelsContainer, panels, window.innerWidth]);

  return (
    <StyledAppWrapper>
      <section
        className="panel"
        id="home"
        ref={(e) => e && createPanelsRefs(e, 0)}
        style={{
          height: "100vh",
        }}
      >
        <FirstScreen />
      </section>
      <section
        className="panel"
        id="ourstrengths"
        ref={(e) => e && createPanelsRefs(e, 1)}
      >
        <SecondScreen ref={text2ref} />
      </section>
      <section
        className="panel"
        ref={(e) => e && createPanelsRefs(e, 2)}
      >
        <ThirdScreen ref={text3ref} />
      </section>
      <section
        className="panel"
        ref={(e) => e && createPanelsRefs(e, 3)}
      >
        <FourthScreen ref={text4ref} />
      </section>
      <section
        className="panel"
        ref={(e) => e && createPanelsRefs(e, 4)}
      >
        <FifthScreen ref={text5ref} />
      </section>
      <section
        className="panel"
        ref={(e) => e && createPanelsRefs(e, 5)}
      >
        <SixthScreen ref={text6ref} />
      </section>
      <section
        className="panel"
        id="whoweare"
        ref={(e) => e && createPanelsRefs(e, 6)}
      >
        <SeventhScreen ref={text7ref} />
      </section>
      <section
        className="panel"
        id="careers"
        ref={(e) => e && createPanelsRefs(e, 7)}
      >
        <EighthScreen ref={text8ref} />
      </section>
      <section
        className="panel"
        id="getintouch"
        ref={(e) => e && createPanelsRefs(e, 8)}
      >
        <NinethScreen />
      </section>
    </StyledAppWrapper>
  );
});

export default Mobile;
