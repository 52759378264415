import styled from "@emotion/styled";

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 2rem;
  right: 1rem;
  font-size: 0.8rem;

  @media screen and (max-width: 1100px) {
    position: static;
    display: flex;
    justify-content: flex-end;
    font-size: 0.6rem;
  }
`;

const SectionText = ({ text }: { text: string }) => {
  return <StyledWrapper>{text}</StyledWrapper>;
};

export default SectionText;
