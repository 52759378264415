import styled from "@emotion/styled";
import ArtificialText from "../components/text/ArtificialText";
import SectionText from "../components/text/SectionText";
import { email } from "../staticText/staticText";

const StyledWrapper = styled.div`
  @media screen and (max-width: 1100px) {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  a:hover {
    color: #b2f6ff;
  }
`;

const NinethScreen = () => {
  return (
    <StyledWrapper>
      <a
        href={`mailto:${email}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {email}
      </a>
      <ArtificialText />
    </StyledWrapper>
  );
};

export default NinethScreen;
