import styled from "@emotion/styled";
import { forwardRef, useState } from "react";
import PageText from "../components/text/PageText";
import { ReactComponent as MenuClose } from "../components/icons/menuClose.svg";
import Spiral from "../components/svg/spiral/Spiral";
import { useWindowSize } from "../hooks/useWindowSize";
import SectionText from "../components/text/SectionText";
import { Modal } from "@mantine/core";
import { TextRef } from "../appwrappers/Desktop";

const StyledHoverWrapper = styled.div`
  display: flex;
  cursor: pointer;
  .hover-text-item {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    > span {
      margin-right: 0.5rem;
    }
  }

  .hover-data-wrapper {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    max-width: 30rem;
  }

  .hover-active {
    color: #b2f6ff;
  }

  @media screen and (max-width: 1100px) {
    .hover-text-item {
      font-size: 0.9rem;
    }

    .hover-data-wrapper {
      display: none;
    }
  }

  @media screen and (max-height: 900px) and (max-width: 1500px) {
    .hover-text-item,
    .hover-data-wrapper {
      font-size: 0.9rem;
    }
  }
`;

const StyledWrapper = styled.div`
  z-index: 1;
  transition: all 200ms ease-in;

  .gradient {
    position: absolute;
    top: 0;
    z-index: 5;
    right: 40%;
    width: 60%;
    height: 100%;
    background: linear-gradient(
      90.76deg,
      rgba(0, 0, 0, 0.71) 63.65%,
      rgba(0, 0, 0, 0) 98.1%
    );
  }

  .text {
    position: absolute;
    top: 50%;
    left: 35%;
    z-index: 2;
  }

  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .gradient {
      display: none;
    }
    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      svg {
        position: initial;

        stroke: #b2f6ff;
        stroke-width: 1.5px;
        height: 15rem;
        fill: none;
        > path {
          visibility: initial;
        }
      }
    }
  }
`;

const hoverItems = [
  {
    text: "dynamic fields",
    hoverText:
      "dynamic pricing, offer management, network design & optimization, operations planning and resilience, digital twins, recommendations, channel management",
  },
  {
    text: "working with dynamic industries",
    hoverText:
      "financial, fintech, air, travel, logistics, hospitality, digital retailers",
  },
  {
    text: "partnering with dynamic people",
    hoverText: "managements, visioners, disruptors",
  },
];

const HoverText = () => {
  const [hoverData, setHoverData] = useState<string>();
  const { width } = useWindowSize();
  return (
    <StyledHoverWrapper>
      <div className="hover-text-item-container">
        {hoverItems.map(({ text, hoverText }) => (
          <div
            className={`hover-text-item ${
              hoverData === hoverText && "hover-active"
            }`}
            key={text}
            onClick={() => setHoverData(hoverText)}
            {...(width && width > 1100
              ? {
                  onMouseEnter: () =>
                    setHoverData(hoverText),
                }
              : {})}
            {...(width && width > 1100
              ? {
                  onMouseLeave: () =>
                    setHoverData(undefined),
                }
              : {})}
          >
            <span>{text}</span>
            <MenuClose />
          </div>
        ))}
      </div>
      {hoverData && (
        <div className="hover-data-wrapper">
          {hoverData}
        </div>
      )}
      {width && width < 1100 && (
        <Modal
          withCloseButton={false}
          centered
          opened={!!hoverData}
          onClose={() => setHoverData(undefined)}
          styles={{
            modal: {
              backgroundColor: "rgba(255,255,255,1)",
            },
          }}
        >
          <div>{hoverData}</div>
        </Modal>
      )}
    </StyledHoverWrapper>
  );
};

const SixthScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <StyledWrapper>
      <div className="gradient"></div>
      <div className="svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2139.21 1853.33"
        >
          <Spiral />
        </svg>
      </div>
      <PageText
        upper="we are at home in"
        lower="dynamic context and digital disruption"
        paragraph="our founders and people led businesses and led the digital and analytics foundations of world-renowned business disruptions. We are impactful in"
        other={<HoverText />}
        ref={ref}
      />
      <SectionText text="our strengths" />
    </StyledWrapper>
  );
});

export default SixthScreen;
