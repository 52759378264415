import styled from "@emotion/styled";
import { forwardRef } from "react";

interface Props {
  position?: {
    bottom?: string | number;
    left?: string | number;
    right?: string | number;
    top?: string | number;
  };
  height: string;
  marginBottom?: string;
}

const StyledSvg = styled.svg<{
  height: string;
  marginBottom?: string;
}>`
  /* position: absolute; */
  fill: none;
  z-index: 1;
  margin: 0.2rem;
  /* left: 5%; */
  /* bottom: 10%; */
  height: ${({ height }) => height};
  opacity: 1;

  @media screen and (max-width: 1100px) {
    margin-bottom: ${({ marginBottom }) => marginBottom};
  }
`;

const HorseSmall = forwardRef<SVGElement, Props>(
  ({ position, height, marginBottom }, ref) => {
    return (
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 98.63 134.62"
        stroke="#b2f6ff"
        style={{ ...position }}
        height={height}
        marginBottom={marginBottom}
      >
        <path
          d="M97.81,120.34c0,7.44-4.8,13.48-10.74,13.48H.19c0-7.45,4.8-13.48,10.74-13.48Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.83px"
          d="M79.72,121.6c0,6.06-3.92,11-8.75,11H.19c0-6.06,3.91-11,8.75-11Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.67px"
          d="M61.62,122.84c0,4.68-3,8.48-6.75,8.48H.19c0-4.68,3-8.48,6.75-8.48Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.5px"
          d="M43.53,124.09c0,3.3-2.14,6-4.77,6H.19c0-3.3,2.13-6,4.76-6Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.33px"
          d="M25.43,125.34c0,1.92-1.24,3.48-2.78,3.48H.19c0-1.92,1.24-3.48,2.77-3.48Z"
          transform="translate(0.31 0.31)"
        />
        <path
          d="M9.54,105.4c0,6,3.89,10.89,8.68,10.89H88.45c0-6-3.88-10.89-8.68-10.89Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.83px"
          d="M23.89,106.39c0,4.93,3.18,8.91,7.1,8.91H88.45c0-4.93-3.18-8.91-7.1-8.91Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.67px"
          d="M38.25,107.38c0,3.82,2.48,6.92,5.53,6.92H88.45c0-3.82-2.47-6.92-5.52-6.92Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.5px"
          d="M52.6,108.37c0,2.73,1.76,4.95,3.94,4.95H88.45c0-2.74-1.77-4.95-3.94-4.95Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.33px"
          d="M67,109.36a2.72,2.72,0,0,0,2.37,3H88.45c0-1.64-1.06-3-2.36-3Z"
          transform="translate(0.31 0.31)"
        />
        <path
          d="M17.32,35.26C10.13,61.88,18.51,86.82,47,101.34H18.42C-1.81,79.62,16.41,35.26,17.32,35.26Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.78px"
          d="M14.88,45.57c-5.52,21.19,2.33,40.52,25,51.87l-22.69.2C1,80.52,14.16,45.58,14.88,45.57Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.56px"
          d="M12.7,55.91C8.84,71.67,15.9,85.38,32.86,93.54l-16.81.4C3.85,81.44,12.16,55.94,12.7,55.91Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.33px"
          d="M11,66.43C8.85,76.75,14.6,84.68,25.79,89.64l-10.92.59C6.68,82.35,10.69,66.44,11,66.43Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.11px"
          d="M10.93,76.05c-.53,4.9,2.36,7.92,7.8,9.69l-5,.8C9.51,83.26,10.76,76.07,10.93,76.05Z"
          transform="translate(0.31 0.31)"
        />
        <path
          d="M95.74,59.77C92.08,63.19,91.35,71.81,84,69c-.64.56-1.91.41-2.89.57a.52.52,0,0,1-.29-.09C77.71,68.19,71.7,68.2,70,65.91s1.3-5,1.34-8.23-1-4.38-4.3-4.93a41.29,41.29,0,0,1-11.43-3.83C48.76,45.5,45.21,39.83,45.66,32c.05-.77,0-1.54,0-2.65-3.2,5.22-2.21,11.12-.13,17.19C50,59.53,59.69,71.38,66.2,77.69c3.62,2.74,7.39,5.31,10.9,8.19C82.18,90.05,84,95,82.78,101.17H61.44C45.07,95.8,31.56,86.64,24.29,70.42a55.4,55.4,0,0,1-4.43-26.24,50.71,50.71,0,0,1,8.63-25.45C36.5,7.3,48.57,2.39,62,.74,68.6-.07,75.4.35,82.13.2c-.06,7.18-6.72,8.4-12.73,10.22C78.32,27.75,87.13,43,95.74,59.77Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.85px"
          d="M32.18,17.56C39.93,8.56,50.91,4,60.11,2.35c4.65-.84,14.23-.61,19-.29A7.77,7.77,0,0,1,77,5.4c-4.17,3.1-6.52,3.31-10,5.36,6.09,11,12.2,22.51,17.25,30.55,2.95,4.7,6,11.36,8.92,17.67-1.49,2.23-2.36,5.64-3.9,7.67-1.4,1.86-3.35,2.12-5.89,1.15-.51.45-1.54.1-2.36,0a1,1,0,0,1-.23-.12c-2.5-1.48-7.39-2.12-8.89-4.58-1.44-2.29.84-4.91.74-7.85-.09-1.73-.47-2.85-1.35-3.45a8,8,0,0,0-3-1,36.51,36.51,0,0,1-10.69-4c-6.35-3.49-10-9-10-15.9,0-.74-.33-3.9-.37-4.88-2.88,3.45-5.58,9.58-4.73,15.17a59.41,59.41,0,0,0,1.91,7.79c3,9.22,9.56,19.6,14.5,25.64,8,9.52,19.29,16,19.63,20a18.31,18.31,0,0,1-.05,3.7c-.57.86-17.5-.21-17.5-.21A28.28,28.28,0,0,1,48.76,93.7C40,88.3,32.16,79.85,28,70.7c-3-6.56-5.06-16.21-5.05-26.36S25.66,24.78,32.18,17.56Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.7px"
          d="M36.14,16.82c6.86-7.48,17.6-11.7,24.48-13,.93-.18,12.12-1.17,14.91-.38C75.2,4.35,64.6,10.88,64,11.21l1.41,2.63C72,25.05,77.87,35.21,82.57,41.3c2.58,3.33,5.41,10.55,8.06,16.89-.89,2.48-1.45,5.7-2.6,7.49-1.3,2-3.3,1.66-5.21.93-.37.34-1.11-.18-1.81-.55a.65.65,0,0,1-.18-.15c-1.94-1.64-5.7-3-7-5.59-1.2-2.32.37-4.87.12-7.47-.21-1.64-.65-2.7-1.5-3.13a11.5,11.5,0,0,0-2.85-.89,33.67,33.67,0,0,1-9.94-4.26C53.83,41,50,35.63,49.52,29.7c-.13-.7-1.18-4.72-1.25-5.56-2.55,1.68-6.35,7.05-6.8,12.24a41.38,41.38,0,0,0,0,7.3c1.2,8,5.69,19.25,10.18,26.12a95.46,95.46,0,0,0,8.23,10.13c2.89,3.27,6.34,5.19,13.54,11.54.88.77,1.73,3.66,1.72,4.82-4.66,0-18.76,1.77-27.41-5.77C40.58,84.29,35.07,78.93,31.61,71.6c-2.45-5.22-5-15.85-5.66-27C25.35,33.56,28.26,23.87,36.14,16.82Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.55px"
          d="M39.93,16.41a50.48,50.48,0,0,1,20.4-11c1.27-.29,9.37-1.63,11-1.35-.67,1.64-11.13,8-11.13,8,.64,1.56,2.92,3.65,4.24,6,7.14,11.4,11.2,19.89,16.42,23.21,1.69,2.34,4.83,9.76,7.2,16.12-.29,2.74-.51,5.79-1.32,7.3-1.2,2.23-3.25,1.21-4.52.72-.25.23-.71-.42-1.27-1.11l-.13-.17c-1.36-1.83-4-3.82-5-6.62-.94-2.35-.09-4.82-.48-7.09-.33-1.54-.84-2.53-1.66-2.82s-1.62-.47-2.72-.74a30.2,30.2,0,0,1-9.19-4.45c-5.4-3.64-9.34-8.86-10.32-13.83-.2-.67-2.31-5.43-2.42-6.14-2.23-.09-5.13,4.2-7.55,8.9a18.38,18.38,0,0,0-1.53,5.87c-.47,6.63,2.43,20.5,6.47,28.19C49,72.54,57.58,80.57,60.85,83.71c.81.78,11.5,9.47,11.63,10.54C66.93,94.88,56.12,94,51.73,91,31.48,77.05,30.24,57,29,44.77,27.78,32.81,30.79,24,39.93,16.41Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.4px"
          d="M43,16.41A56.42,56.42,0,0,1,68.73,5a11.12,11.12,0,0,0-2,1.11A96.7,96.7,0,0,0,56.49,13.3,32.78,32.78,0,0,0,61,20.65C68.64,32.23,74.05,40.17,79.2,41.24c1.16,1.12,4.26,9,6.34,15.34.31,3,.47,5.91,0,7.13-1,2.43-3.18.74-3.81.5-.13.11-.37-.64-.75-1.68a.8.8,0,0,0-.07-.2c-.79-2-2.31-4.66-3.12-7.63-.69-2.39-.54-4.77-1.07-6.71-.45-1.45-1-2.37-1.82-2.51a20.89,20.89,0,0,1-2.6-.61,27.72,27.72,0,0,1-8.45-4.68c-4.92-3.71-9-8.79-10.45-12.8-.29-.64-3.79-6.37-3.93-6.93-3.75-.06-5.66,2.8-8.24,7.29a27.52,27.52,0,0,0-1.86,4.71c-1.89,5.3-1.33,17.2,2,26.11,2.94,13.05,12,21,12.84,22A91.93,91.93,0,0,0,69.44,92.86c-1.06-.34-9.73-.4-14.84-3.23C36.93,79.88,32.45,59,31.47,45.59,30.49,32.61,32.83,24.44,43,16.41Z"
          transform="translate(0.31 0.31)"
        />
        <path
          strokeWidth="0.25px"
          d="M65,91S52.15,87.34,44.21,72.72c-6.93-12.74-11.74-34.29-5.93-44C42.81,21.14,48.53,15,50.92,19c3,4.9,18.35,25.65,25.37,25.63,3.59,0,5.95,17.54,5.95,17.54s1.29,3.28,2,.58S78.92,42.27,78,42.49c-5,1.18-11.06-6.75-19.25-18.52-2.86-4.08-7.64-7.66-5.95-9.14s6.94-5.78,7.71-6.59c0,0-10.38,4.62-15.66,9.47-10,9.21-11.95,14.88-11.13,27.86.93,14.53,9,36.7,22.82,43C62,91.06,65,91,65,91Z"
          transform="translate(0.31 0.31)"
        />
      </StyledSvg>
    );
  },
);

export default HorseSmall;
