import styled from "@emotion/styled";
import { forwardRef } from "react";

const StyledSvg = styled.svg`
  position: absolute;
  fill: none;
  z-index: 1;
  right: 5%;
  height: 50%;
  opacity: 0.8;

  @media screen and (max-width: 1100px) {
    position: initial;
    height: 15rem;
  }
`;

const Cube = forwardRef<SVGElement>((props, ref) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 603 504"
      stroke="#b2f6ff"
    >
      <path d="M536.69,298.1c-6.7-14.13-22.55-19.37-35.31-11.67s-17.68,25.47-11,39.6S513,345.4,525.72,337.7,543.4,312.23,536.69,298.1Zm-12,37.53c-11.73,7.08-26.28,2.26-32.45-10.74s-1.65-29.3,10.08-36.38,26.29-2.28,32.46,10.71S536.47,328.55,524.74,335.63Z" />
      <path d="M539.16,296.6c-7.42-15.63-24.95-21.41-39.07-12.89s-19.56,28.16-12.13,43.8S512.9,349,527,340.44,546.58,312.24,539.16,296.6ZM526,338.37c-13.1,7.9-29.34,2.52-36.22-12s-1.84-32.7,11.25-40.61,29.34-2.54,36.23,12S539.13,330.47,526,338.37Z" />
      <path d="M541.9,295c-8.22-17.31-27.62-23.71-43.25-14.28S477,311.85,485.22,329.16s27.61,23.74,43.24,14.31S550.12,312.27,541.9,295ZM527.48,341.4c-14.61,8.82-32.72,2.82-40.4-13.36s-2.05-36.49,12.55-45.3,32.73-2.84,40.41,13.34S542.08,332.59,527.48,341.4Z" />
      <path d="M544.94,293.12c-9.1-19.18-30.58-26.28-47.89-15.83s-24,34.54-14.89,53.72,30.59,26.28,47.9,15.83S554,312.29,544.94,293.12Zm-15.87,51.65c-16.26,9.82-36.48,3.15-45.05-14.89s-2.26-40.71,14-50.53,36.49-3.13,45.05,14.89S545.36,334.94,529.07,344.77Z" />
      <path d="M548.32,291.08c-10.08-21.24-33.87-29.11-53.05-17.54s-26.57,38.26-16.49,59.51,33.88,29.1,53.05,17.53S558.4,312.32,548.32,291.08Zm-17.47,57.43c-18.15,11-40.66,3.52-50.2-16.59s-2.55-45.36,15.6-56.31,40.66-3.51,50.21,16.59S549,337.56,530.85,348.51Z" />
      <path d="M552.09,288.81c-11.17-23.54-37.54-32.27-58.8-19.44S463.85,311.79,475,335.32s37.55,32.25,58.79,19.43S563.26,312.36,552.09,288.81Zm-19.26,63.88c-20.22,12.2-45.32,3.9-56-18.5s-2.82-50.55,17.39-62.75,45.32-3.9,56,18.49S553.05,340.48,532.83,352.69Z" />
      <path d="M556.27,286.28c-12.38-26.08-41.61-35.74-65.17-21.52s-32.63,47-20.25,73.07S512.47,373.6,536,359.39,568.66,312.38,556.27,286.28Zm-21.24,71c-22.52,13.59-50.48,4.33-62.32-20.61s-3.15-56.3,19.37-69.89,50.49-4.36,62.33,20.59S557.55,343.73,535,357.32Z" />
      <path d="M560.91,283.48c-13.73-28.93-46.14-39.64-72.26-23.88s-36.19,52.11-22.46,81,46.14,39.65,72.26,23.89S574.64,312.41,560.91,283.48Zm-23.44,79c-25.07,15.13-56.22,4.85-69.41-22.94s-3.5-62.72,21.57-77.85,56.23-4.84,69.41,22.94S562.56,347.32,537.47,362.46Z" />
      <path d="M566.07,280.37c-15.23-32.08-51.17-44-80.13-26.49s-40.13,57.8-24.91,89.88,51.18,44,80.13,26.48S581.29,312.45,566.07,280.37Zm-25.89,87.8c-27.93,16.86-62.58,5.39-77.26-25.55s-3.92-69.79,24-86.65,62.59-5.42,77.27,25.52S568.11,351.32,540.18,368.17Z" />
      <path d="M571.8,276.91c-16.89-35.58-56.76-48.76-88.87-29.37s-44.51,64.1-27.63,99.68c12.54,26.4,37.74,40.47,63,38l5.13-3.1c-26.19,5-53.27-8.67-66.27-36.08-16.35-34.44-4.36-77.7,26.73-96.46s69.67-6,86,28.41c13,27.41,8.07,60.42-10.15,82.21l5.13-3.1C580.73,334.76,584.33,303.32,571.8,276.91Z" />
      <path d="M578.17,273.06c-18.73-39.46-63-54.06-98.58-32.56S430.22,311.58,449,351.05c11.65,24.54,33.15,39.47,56.31,42.09l3.41-2.06c-23.69-1.48-46-16.2-57.85-41.16-18.2-38.33-4.85-86.47,29.75-107.36s77.54-6.7,95.73,31.63c11.84,24.95,10.32,54.07-1.73,77.12l3.41-2.06C588.9,326.06,589.81,297.61,578.17,273.06Z" />
      <path d="M586.57,344.07l2.84-1.72a101.32,101.32,0,0,0,4.69-41.1l-2.91-6.13A98.36,98.36,0,0,1,586.57,344.07Z" />
      <path d="M475.87,232.66c-39.53,23.86-54.77,78.87-34,122.66,11.13,23.46,30.39,39,51.95,44.72l2.84-1.71c-21.93-5-41.68-20.44-52.93-44.14-20.24-42.65-5.39-96.23,33.11-119.46,31.19-18.83,68.47-12,92.27,13.89l-2.91-6.14C541.74,219.58,506,214.46,475.87,232.66Z" />
      <path d="M600.55,335.63A112.08,112.08,0,0,0,603,320l-1.84-3.87a109.77,109.77,0,0,1-3.15,21Z" />
      <path d="M471.73,224C427.87,250.42,411,311.48,434,360.07c10.79,22.73,28.45,38.77,48.68,46.69l2.54-1.53c-20.51-7.47-38.49-23.41-49.35-46.29-22.52-47.46-6-107.07,36.83-132.93,28.24-17,61-15.21,86.43,1.59l-1.83-3.87C531.67,208.26,499.57,207.15,471.73,224Z" />
      <path d="M467.14,214.27c-48.67,29.37-67.45,97.15-41.87,151.07,10.54,22.21,27,38.65,46.09,48.26l2.35-1.42c-19.32-9.29-36-25.66-46.57-48-25.05-52.77-6.66-119.12,41-147.88,26.78-16.16,57.17-17.06,83.11-5.4l-1.51-3.18C523.74,196.9,493.69,198.24,467.14,214.27Z" />
      <path d="M462,203.51c-54,32.6-74.88,107.84-46.47,167.69,10.34,21.8,25.82,38.59,43.92,49.57l2.25-1.36c-18.28-10.74-33.91-27.48-44.29-49.34C389.57,311.35,410,237.58,463,205.59c25.83-15.59,54.7-18.41,80.73-10.43l-1.35-2.84C516.37,184.89,487.72,188,462,203.51Z" />
      <path d="M456.37,191.57c-60,36.2-83.12,119.69-51.58,186.13,10.19,21.48,24.86,38.58,42.08,50.68l2.15-1.3c-17.36-11.92-32.15-29-42.37-50.5-31-65.29-8.24-147.37,50.7-182.94,25.16-15.19,52.91-19.48,78.87-14.35L535,176.66C509,171.93,481.44,176.44,456.37,191.57Z" />
      <path d="M450.07,178.3c-66.58,40.18-92.27,132.87-57.27,206.64a144.45,144.45,0,0,0,40.52,51.62l2.09-1.26a142,142,0,0,1-40.74-51.49c-34.47-72.63-9.18-163.88,56.38-203.45,24.66-14.88,51.55-20.36,77.35-17.54l-1.18-2.48C501.45,157.82,474.66,163.45,450.07,178.3Z" />
      <path d="M443.06,163.53C369.14,208.15,340.61,311.08,379.48,393a161.76,161.76,0,0,0,39.15,52.44l2.06-1.24a159.15,159.15,0,0,1-39.33-52.34C343,311.1,371.17,209.61,444.05,165.62c24.27-14.65,50.49-21.1,76.08-20.22L519,143C493.42,142.34,467.29,148.92,443.06,163.53Z" />
      <path d="M364.69,401.9a181.18,181.18,0,0,0,38,53.17l2-1.21a178.55,178.55,0,0,1-38.1-53.08C323.94,311,355.22,198.13,436.27,149.22a153.5,153.5,0,0,1,75-22.5l-1.1-2.31a155.52,155.52,0,0,0-74.88,22.74C353.21,196.69,321.54,311,364.69,401.9Z" />
      <path d="M348.25,411.82a202.36,202.36,0,0,0,36.91,53.8l2-1.19a199.79,199.79,0,0,1-37-53.73c-47.38-99.83-12.6-225.31,77.51-279.69a171.53,171.53,0,0,1,74.06-24.47l-1.07-2.26a173.66,173.66,0,0,0-74,24.66C335.51,184,300.34,310.86,348.25,411.82Z" />
      <path d="M330,422.84a226.68,226.68,0,0,0,36,54.36l2-1.18a223.71,223.71,0,0,1-36.07-54.31c-52.67-111-14-250.46,86.16-310.92A192,192,0,0,1,491.28,84.6l-1.06-2.22a193.86,193.86,0,0,0-73.17,26.35C315.83,169.81,276.79,310.71,330,422.84Z" />
      <path d="M309.7,435.09A253.1,253.1,0,0,0,344.86,490l1.94-1.17A249.76,249.76,0,0,1,311.57,434C253,310.59,296,155.52,407.36,88.3a214.46,214.46,0,0,1,72.53-27.69l-1-2.19a216.48,216.48,0,0,0-72.47,27.82C294,154.07,250.61,310.58,309.7,435.09Z" />
      <path d="M311.26,490.22q5,7.11,10.31,13.78l1.94-1.17a273.72,273.72,0,0,1-19.07-27Z" />
      <path d="M269.89,403.05l6.82,14.37c-45.49-131.34,3.92-284.75,118.8-354.08a239.71,239.71,0,0,1,71.9-29l-1-2.18a242,242,0,0,0-71.86,29.12C283.11,128.49,232.76,274.19,269.89,403.05Z" />
      <path d="M308.7,99.09l-5.59,3.37c-47.7,59.64-73.75,137-73.59,215.52l2.14,4.52C230.44,240.76,257.84,160.05,308.7,99.09Z" />
      <path d="M244,138.12l-3.53,2.14a388.17,388.17,0,0,0-38.23,120.28L203.9,264A385.8,385.8,0,0,1,244,138.12Z" />
      <path d="M191.38,169.9l-2.89,1.74Q182.12,189.51,177.3,208l1.41,3Q184,190.07,191.38,169.9Z" />
      <path d="M32.52,210.69c6,12.7,16.25,23.38,22.77,23.79s6.94-9.6.91-22.3S40,188.81,33.43,188.4,26.49,198,32.52,210.69Zm1.79-20.44c6,.38,15.4,10.19,20.94,21.87s5.16,20.86-.84,20.48S39,222.43,33.48,210.75,28.3,189.88,34.31,190.25Z" />
      <path d="M31.26,210.61c6.67,14.06,18,25.86,25.19,26.31s7.69-10.61,1-24.66-18-25.87-25.21-26.32S24.59,196.56,31.26,210.61Zm1.88-22.82c6.7.42,17.19,11.37,23.37,24.41s5.76,23.28-.94,22.86S38.4,223.71,32.22,210.67,26.44,187.37,33.14,187.79Z" />
      <path d="M29.86,210.53c7.39,15.56,19.89,28.61,27.89,29.12s8.5-11.74,1.12-27.3S39,183.71,31,183.21,22.48,195,29.86,210.53Zm28.05,1.76c6.9,14.54,6.43,26-1,25.5s-19.15-12.66-26-27.2-6.45-26,1-25.52S51,197.75,57.91,212.29Z" />
      <path d="M28.3,210.43c8.17,17.23,22,31.71,30.89,32.26s9.41-13,1.24-30.24-22-31.71-30.9-32.27S20.12,193.19,28.3,210.43ZM30.41,182c8.33.52,21.37,14.13,29.06,30.35s7.18,29-1.16,28.44-21.37-14.15-29.06-30.34S22.08,181.51,30.41,182Z" />
      <path d="M26.57,210.32c9.06,19.09,24.41,35.12,34.22,35.73s10.42-14.41,1.36-33.5-24.41-35.12-34.22-35.73S17.51,191.23,26.57,210.32Zm2.24-31.65c9.29.59,23.81,15.75,32.39,33.82s8,32.29-1.29,31.71S36.1,228.45,27.52,210.38,19.52,178.09,28.81,178.67Z" />
      <path d="M24.64,210.2c10,21.15,27.06,38.92,37.93,39.6s11.55-16,1.51-37.13C56.62,197,45.32,183.11,35.54,176.75l-2.85-.18c10,4.79,22.49,19.3,30.44,36,9.55,20.13,8.9,36-1.44,35.33s-26.54-17.55-36.1-37.68c-7.94-16.75-8.83-30.53-2.95-34.32l-2.84-.18C15.64,181.25,17.18,194.47,24.64,210.2Z" />
      <path d="M22.51,210.06c11.12,23.44,30,43.13,42,43.89s12.81-17.7,1.68-41.14C59.29,198.2,49.36,185.05,39.94,177l-1.79-.11c9.57,7.44,20,20.84,27.12,35.83,10.64,22.42,9.91,40.07-1.61,39.34s-29.56-19.55-40.2-42c-7.11-15-9.15-27.86-6.28-34.52l-1.79-.11C13.25,182.79,15.57,195.45,22.51,210.06Z" />
      <path d="M20.13,209.91c12.34,26,33.24,47.84,46.61,48.68S80.94,239,68.6,213C62,199,52.86,186.23,43.79,177.27l-1.47-.09c9.2,8.64,18.59,21.53,25.32,35.72,11.85,25,11.06,44.64-1.78,43.83S32.93,234.94,21.08,210c-6.73-14.18-9.38-26.65-8.06-34.63l-1.47-.09C10.67,183.58,13.5,195.94,20.13,209.91Z" />
      <path d="M17.48,209.75c13.69,28.83,36.88,53,51.7,54s15.74-21.77,2.06-50.6a147.87,147.87,0,0,0-23.71-35.61l-1.31-.09a141.56,141.56,0,0,1,24.06,35.64c13.2,27.81,12.3,49.69-2,48.79s-36.64-24.23-49.84-52C12,196.14,8.88,183.9,9.12,175.09H8.87L7.8,175C7.86,184,11.06,196.21,17.48,209.75Z" />
      <path d="M71.88,269.43c16.44,1,17.46-24.15,2.29-56.12a166.29,166.29,0,0,0-22.82-35.56l-1.22-.08a159.91,159.91,0,0,1,23.08,35.58C87.9,244.2,86.9,268.55,71,267.56s-40.78-27-55.47-57.94C9.2,196.32,5.78,184.23,5.2,174.85L4,174.77a87.37,87.37,0,0,0,5.12,21.77l12.41,26.14C36.77,248.57,57.76,268.54,71.88,269.43Z" />
      <path d="M0,174.52c.2,1.42.45,2.88.74,4.39l2,4.1a80.86,80.86,0,0,1-1.54-8.42Z" />
      <path d="M74.88,275.75c18.24,1.15,19.38-26.77,2.55-62.24A186.46,186.46,0,0,0,55.34,178l-1.15-.07a180.2,180.2,0,0,1,22.28,35.52C92.82,247.9,91.71,275,74,273.9c-12.82-.81-30.87-16.19-46.06-37.69l1.95,4.1C45,260.68,62.36,275,74.88,275.75Z" />
      <path d="M78.23,282.8c20.22,1.27,21.5-29.71,2.82-69.06a207.83,207.83,0,0,0-21.47-35.48l-1.1-.07a202.18,202.18,0,0,1,21.61,35.49c18.2,38.33,17,68.5-2.74,67.26-12-.75-28-12.9-42.85-30.9l1.47,3.1C50.72,270.48,66.42,282.06,78.23,282.8Z" />
      <path d="M81.94,290.63c22.45,1.41,23.86-33,3.13-76.64a231.61,231.61,0,0,0-20.93-35.44l-1.07-.07a226.1,226.1,0,0,1,21.05,35.45c20.24,42.65,18.86,76.22-3.06,74.84-11.45-.72-26.28-10.84-40.71-26.42L41.62,265C56,280.16,70.58,289.91,81.94,290.63Z" />
      <path d="M89.54,214.27a256.27,256.27,0,0,0-20.48-35.41l-1-.07a250.92,250.92,0,0,1,20.56,35.42c22.51,47.44,21,84.79-3.39,83.26-11.1-.7-25.07-9.35-39.11-23l1.15,2.44c14,13.36,27.79,21.77,38.84,22.46C111,300.89,112.53,262.73,89.54,214.27Z" />
      <path d="M94.51,214.59A284.07,284.07,0,0,0,74.44,179.2l-1-.07a278.69,278.69,0,0,1,20.13,35.4c25,52.77,23.35,94.3-3.78,92.6-10.85-.69-24.16-8.2-37.83-20.34L53,289.1C66.64,301,79.85,308.32,90.66,309,118.31,310.74,120,268.38,94.51,214.59Z" />
      <path d="M100,214.93a313.55,313.55,0,0,0-19.71-35.36l-1-.07a309.19,309.19,0,0,1,19.76,35.37c27.85,58.68,26,104.89-4.2,103-10.66-.67-23.46-7.27-36.77-18.11l1,2.2c13.26,10.64,26,17.1,36.61,17.77C126.45,321.65,128.37,274.65,100,214.93Z" />
      <path d="M106.16,215.32A347.78,347.78,0,0,0,86.77,180l-1-.06a342.18,342.18,0,0,1,19.44,35.35c31,65.27,28.87,116.65-4.68,114.54-10.51-.66-22.88-6.5-35.87-16.21l1,2.11c12.94,9.56,25.26,15.3,35.75,16C135.49,333.8,137.62,281.61,106.16,215.32Z" />
      <path d="M113,215.75a383.56,383.56,0,0,0-19.11-35.33l-1-.07A382.75,382.75,0,0,1,112,215.69c34.44,72.56,32.11,129.7-5.2,127.36-10.39-.65-22.41-5.85-35.1-14.59l1,2.08c12.64,8.61,24.63,13.73,35,14.38C145.54,347.3,147.9,289.35,113,215.75Z" />
      <path d="M120.54,216.22c-5.75-12.12-12.09-23.94-18.86-35.31l-1-.06c6.79,11.36,13.14,23.19,18.9,35.31,38.29,80.7,35.71,144.24-5.77,141.63-10.29-.65-22-5.3-34.43-13.18l1,2c12.38,7.77,24.08,12.37,34.36,13C156.7,362.29,159.32,297.94,120.54,216.22Z" />
      <path d="M129,216.75c-5.73-12.07-12-23.88-18.64-35.3l-1-.06c6.67,11.41,12.94,23.22,18.67,35.3,42.58,89.72,39.71,160.36-6.42,157.46-10.2-.64-21.69-4.83-33.84-11.94l.94,2c12.13,7,23.59,11.18,33.79,11.82C169.11,378.94,172,307.49,129,216.75Z" />
      <path d="M131.09,394.18c51.8,3.26,55-76.07,7.21-176.84-5.71-12-11.89-23.83-18.44-35.29l-1-.06c6.56,11.45,12.75,23.25,18.47,35.29,47.33,99.74,44.13,178.27-7.14,175-10.14-.64-21.41-4.43-33.34-10.87l.93,2C109.71,389.8,121,393.55,131.09,394.18Z" />
      <path d="M140.68,414.4c57.53,3.61,61.11-84.51,8-196.41-5.69-12-11.81-23.78-18.26-35.28l-.32,0-.65,0c6.46,11.49,12.58,23.28,18.28,35.28,52.62,110.88,49.06,198.19-7.93,194.61-10.08-.64-21.17-4.07-32.88-9.91l.92,1.93C119.53,410.35,130.61,413.76,140.68,414.4Z" />
      <path d="M151.34,436.85c63.89,4,67.87-93.85,8.88-218.14q-8.52-17.94-18.1-35.26l-1-.06c6.37,11.52,12.44,23.3,18.12,35.26C217.77,341.92,213.81,439,150.45,435c-10-.63-21-3.76-32.48-9.07l.92,1.94C130.4,433.12,141.31,436.22,151.34,436.85Z" />
      <path d="M163.17,461.78c71,4.46,75.39-104.22,9.87-242.26q-8.49-17.91-18-35.26l-1-.06q9.44,17.33,18,35.26c65,137,60.63,244.89-9.8,240.46-10-.62-20.78-3.48-32.12-8.3l.9,1.9C142.41,458.32,153.19,461.15,163.17,461.78Z" />
      <path d="M176.33,489.51c66.94,4.2,80.56-82.26,38.56-202.35L211,279c45,123.25,32.34,212.91-35.57,208.64-9.95-.62-20.62-3.24-31.81-7.64l.91,1.92C155.72,486.28,166.38,488.88,176.33,489.51Z" />
      <path d="M254.29,370.19l-1.82-3.85c12.3,61.93,7.27,109.9-14.69,134.35l2.54.16C260.91,476.2,265.69,429.78,254.29,370.19Z" />
      <path d="M283.49,431.71l-1.44-3c2.31,29.33.2,54.52-6.28,74.4l1.73.11C283.51,483.86,285.52,459.7,283.49,431.71Z" />
      <path d="M217.12,71.5c5.1.32,16.78-4,26-9.54s12.62-10.38,7.53-10.7-16.78,4-26,9.54S212,71.18,217.12,71.5Zm8.28-10.66c8.5-5.13,19.23-9.06,23.91-8.77s1.59,4.71-6.91,9.84S223.18,71,218.49,70.68,216.9,66,225.4,60.84Z" />
      <path d="M215.35,72.57C221,72.93,233.9,68.2,244.13,62s14-11.49,8.33-11.84-18.56,4.38-28.79,10.56S209.71,72.22,215.35,72.57Zm9.06-11.79c9.49-5.72,21.46-10.12,26.7-9.79s1.76,5.26-7.73,11-21.45,10.12-26.68,9.79S214.93,66.51,224.41,60.78Z" />
      <path d="M213.36,73.77c6.25.39,20.54-4.84,31.86-11.68S260.69,49.37,254.44,49s-20.54,4.85-31.87,11.69S207.12,73.38,213.36,73.77Zm10-13.06c10.58-6.38,23.93-11.28,29.77-10.92s2,5.86-8.61,12.25S220.55,73.32,214.71,73,212.73,67.1,223.32,60.71Z" />
      <path d="M211.14,75.11c6.92.43,22.76-5.37,35.3-12.94s17.12-14.09,10.21-14.52S233.9,53,221.35,60.59,204.23,74.67,211.14,75.11Zm11-14.47c11.8-7.13,26.69-12.59,33.19-12.18s2.21,6.54-9.6,13.66S219,74.7,212.5,74.29,210.31,67.75,222.1,60.64Z" />
      <path d="M208.7,76.59c7.66.48,25.19-6,39.09-14.34s19-15.6,11.3-16.08S233.9,52.12,220,60.51,201,76.1,208.7,76.59Zm12.05-16c13.15-7.94,29.74-14,37-13.56S260.2,54.27,247,62.2s-29.74,14-37,13.57S207.59,68.49,220.75,60.55Z" />
      <path d="M206,78.23c8.49.54,27.93-6.59,43.32-15.88s21-17.29,12.53-17.83S233.9,51.11,218.5,60.41,197.48,77.7,206,78.23Zm13.27-17.77c14.65-8.85,33.15-15.63,41.23-15.12s2.73,8.12-11.92,17S215.4,77.92,207.32,77.42,204.59,69.3,219.24,60.46Z" />
      <path d="M203,80.06c9.41.59,31-7.31,48-17.61s23.3-19.17,13.89-19.76-31,7.31-48,17.62S193.54,79.46,203,80.06Zm14.63-19.71C233.9,50.5,254.5,42.94,263.5,43.51s3,9-13.28,18.89S213.3,79.8,204.3,79.24,201.26,70.2,217.58,60.35Z" />
      <path d="M199.57,82.09c10.44.66,34.32-8.1,53.25-19.52s25.83-21.25,15.4-21.91S233.9,48.77,215,60.19,189.14,81.44,199.57,82.09Zm16.15-21.85c18.18-11,41.13-19.39,51.15-18.76s3.39,10.07-14.8,21S211,81.91,200.93,81.28,197.55,71.2,215.72,60.24Z" />
      <path d="M195.83,84.35c11.58.73,38.07-9,59-21.65S283.53,39.13,272,38.41s-38.07,9-59,21.65S184.26,83.62,195.83,84.35Zm17.83-24.24c20.24-12.22,45.78-21.59,56.94-20.89s3.78,11.21-16.46,23.43S208.36,84.23,197.2,83.52,193.42,72.32,213.66,60.11Z" />
      <path d="M191.68,86.85c12.84.81,42.22-10,65.49-24s31.77-26.13,18.94-26.94-42.22,10-65.49,24S178.85,86.05,191.68,86.85ZM211.37,60c22.53-13.6,51-24,63.39-23.24s4.2,12.47-18.33,26.07-51,24-63.38,23.24S188.84,73.56,211.37,60Z" />
      <path d="M187.08,89.63c14.24.9,46.82-11,72.64-26.63s35.24-29,21-29.88S233.9,44.17,208.08,59.76,172.84,88.74,187.08,89.63ZM208.83,59.8c25.07-15.13,56.72-26.74,70.54-25.87s4.68,13.89-20.4,29-56.71,26.74-70.54,25.87S183.75,74.94,208.83,59.8Z" />
      <path d="M182,92.73c15.8,1,52-12.26,80.59-29.55S301.65,31,285.85,30s-52,12.27-80.6,29.56S166.16,91.74,182,92.73ZM206,59.63c27.9-16.84,63.12-29.76,78.5-28.79s5.2,15.45-22.7,32.29-63.11,29.75-78.5,28.78S178.1,76.47,206,59.63Z" />
      <path d="M176.25,96.17c17.53,1.1,57.65-13.61,89.44-32.8s43.39-35.69,25.86-36.79S233.9,40.2,202.11,59.38,158.72,95.07,176.25,96.17Zm26.61-36.74c31-18.74,70.22-33.11,87.34-32S296,44.59,264.94,63.33s-70.22,33.1-87.34,32S171.81,78.16,202.86,59.43Z" />
      <path d="M169.92,100c19.45,1.22,64-15.11,99.25-36.4s48.16-39.6,28.7-40.82-64,15.1-99.25,36.39S150.47,98.77,169.92,100Zm29.44-40.78c34.55-20.85,78.12-36.82,97.16-35.63s6.45,19.12-28.09,40-78.12,36.82-97.16,35.62S164.84,80.05,199.36,59.21Z" />
      <path d="M162.88,104.24c21.59,1.35,71-16.77,110.17-40.4s53.45-44,31.86-45.32-71,16.77-110.17,40.4S141.29,102.88,162.88,104.24ZM195.49,59c38.41-23.19,86.89-41,108.07-39.64s7.16,21.28-31.25,44.46-86.89,41-108.07,39.63S157.08,82.15,195.49,59Z" />
      <path d="M155.07,109c24,1.51,78.83-18.61,122.3-44.84S336.7,15.3,312.73,13.8c-23.38-1.47-76.18,17.65-119.11,43l-6.3,3.8C146.22,86,131.68,107.48,155.07,109Zm36.11-50.26c42.72-25.78,96.65-45.56,120.2-44.08s8,23.67-34.76,49.45S180,109.61,156.42,108.13,148.46,84.47,191.18,58.69Z" />
      <path d="M146.39,114.19c26.6,1.67,87.51-20.66,135.76-49.78S348,10.23,321.42,8.56c-19.08-1.2-55.79,9.94-92.82,27.08l-3.21,1.93C263,19.84,300.72,8.16,320.06,9.37c26.2,1.65,8.86,26.32-38.65,55s-107.47,50.66-133.67,49c-19.35-1.22-15-15,7.81-33.65l-3.21,1.93C131,99.78,127.31,113,146.39,114.19Z" />
      <path d="M136.73,120c29.54,1.86,97.17-22.94,150.74-55.28s73.13-60.15,43.59-62c-17.85-1.13-49.6,7.48-83.75,21.61L245,25.77c34.5-14.46,66.78-23.35,84.75-22.22,29.13,1.83,9.85,29.26-43,61.15S167.22,121,138.09,119.2c-18-1.13-17.5-12-2.1-27.67L133.63,93C119,108.3,118.88,118.9,136.73,120Z" />
      <path d="M126,126.49c32.8,2.06,107.89-25.48,167.37-61.38,43.17-26,66.44-49.46,63.42-61.13L355,3.86c4.38,11.22-18.67,34.84-62.35,61.21-58.74,35.45-132.89,62.64-165.28,60.6-17.18-1.08-19.21-10.17-8.55-23.77l-2,1.2C106.63,116.49,108.9,125.41,126,126.49Z" />
      <path d="M308.25.93l-1.8-.11A372.35,372.35,0,0,0,264.12,14.2l-2,1.2A362.45,362.45,0,0,1,308.25.93Z" />
      <path d="M293.46,0q-6.27,2-12.8,4.22l-1.8,1.09c5.43-1.9,10.75-3.65,15.91-5.23Z" />
      <path d="M114.1,133.68C150.52,136,233.9,105.39,300,65.53c40.29-24.32,65-46.56,69.84-60.74l-1.3-.08c-4.2,14-28.75,36.31-69.29,60.77-65.3,39.41-147.73,69.64-183.75,67.38C98.79,131.82,95,124,102.08,112l-1.8,1.08C93.47,124.93,97.49,132.63,114.1,133.68Z" />
      <path d="M100.87,141.66c40.45,2.54,133-31.4,206.38-75.67,38.6-23.3,64.29-44.88,74.09-60.47l-1.1-.07c-9.39,15.48-35,37.11-73.74,60.49-72.6,43.81-164.24,77.42-204.28,74.9C86,139.82,80.67,133,85,122.3l-1.68,1C79.23,133.89,84.63,140.64,100.87,141.66Z" />
      <path d="M86.16,150.54c44.92,2.82,147.74-34.89,229.2-84C352.79,43.9,379.3,22.76,392.67,6.23l-1-.06c-13.08,16.46-39.53,37.63-77.05,60.28C233.9,115.16,132,152.52,87.51,149.72c-16-1-22.42-7-20.38-16.63l-1.6,1C63.68,143.55,70.21,149.54,86.16,150.54Z" />
      <path d="M69.81,160.4c49.9,3.14,164.09-38.74,254.56-93.34C361,45,388.14,24.15,404.26,7l-.94-.06c-15.92,17.15-43.05,38-79.7,60.11-89.72,54.15-203,95.68-252.44,92.57-15.74-1-23.16-6.38-23.06-15l-1.56.94C46.61,154.07,54.09,159.42,69.81,160.4Z" />
      <path d="M51.67,171.36c55.41,3.48,182.23-43,282.71-103.67,35.93-21.69,63.71-42.29,82-60l-.89,0c-18.18,17.65-45.91,38.26-81.88,60C233.9,127.84,108,174,53,170.54c-15.56-1-23.8-5.82-25.36-13.63l-1.5.91C27.83,165.57,36.12,170.38,51.67,171.36Z" />
      <path d="M344.76,68.34C254.85,122.6,145.83,166.6,75.23,179.25l2.42.15c71.15-13.53,178.82-57.28,267.85-111C380.92,47,409.2,26.59,429.34,8.53l-.85,0C408.45,26.51,380.2,47,344.76,68.34Z" />
      <path d="M6.45,174.93,7.8,175l1.07.07a14.18,14.18,0,0,1-3.37-4.79L4,171.18A14.3,14.3,0,0,0,6.45,174.93Z" />
      <path d="M105.17,181.13l1.45.09c75.14-21.64,170.14-63.11,251.24-112.06,35-21.12,63.73-41.41,85.39-59.75l-.83-.05C420.84,27.68,392.14,48,357.11,69.12,275.71,118.25,180.21,159.81,105.17,181.13Z" />
      <path d="M251,133.9l3.18-1.92a1209.25,1209.25,0,0,1-125.28,50.64l.52,0,.65,0A1216.07,1216.07,0,0,0,251,133.9Z" />
    </StyledSvg>
  );
});

export default Cube;
