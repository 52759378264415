import styled from "@emotion/styled";
import { forwardRef } from "react";
import { TextRef } from "../appwrappers/Desktop";
import Head from "../components/svg/head/Head";
import PageText from "../components/text/PageText";
import SectionText from "../components/text/SectionText";

const ScreenWrapper = styled.div`
  .svg-wrapper {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 100%;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      padding-left: 4rem;
      height: 15rem;
    }
  }
`;

const FifthScreen = forwardRef<TextRef>((props, ref) => {
  return (
    <ScreenWrapper>
      <div className="svg-wrapper">
        <Head />
      </div>
      <PageText
        upper={"balance of ai & hi - "}
        lower="in the world of ai, we dare to add human intelligence"
        paragraph="while our solutions use advanced science and technology, we focus equally on ways to effectively incorporate the business context, the common sense, the expertise, the creativity, and the tactical wisdom of our clients, partners, and the final users - we believe that 'ai-with-hi' beats 'ai'"
        ref={ref}
      />
      <SectionText text="our strengths" />
    </ScreenWrapper>
  );
});

export default FifthScreen;
